<template>
    <div class="flex flex-col gap-6 px-8 lg:px-28">
        <p class="font-bold text-4xl sm:text-5xl xl:text-6xl animated-block">Solo 3 pasos</p>
        <div class="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            <StepCard class="step-card animated-block" :title="stepTitle1" :icon="qrIcon" />
            <StepCard class="step-card animated-block" :title="stepTitle2" :icon="rankingIcon" />
            <StepCard class="step-card animated-block md:col-span-2 md:justify-self-center xl:col-span-1 xl: justify-self-start" :title="stepTitle3" :icon="musicIcon" />
        </div>
    </div>
</template>

<script setup>
import StepCard from '@/components/Features/StepCard.vue'
import qrIcon from '@/assets/images/qr-code.png'
import rankingIcon from '@/assets/images/ranking-icon.png'
import musicIcon from '@/assets/images/musical-note.png'

const stepTitle1 = "Escanea el QR"

const stepTitle2 = "Vota por tu canción favorita"

const stepTitle3 = "¡Disfruta de la canción más votada!"
</script>