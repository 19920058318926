<template>
    <div id="form" class="w-full py-20">
        <div class="m-auto whitespace-nowrap overflow-hidden pt-5 animated-block">
            <span class="font-bold text-tp-yellow text-4xl md:text-6xl sm:text-8xl uppercase inline-block marquee">
                contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos /
            </span>
        </div>

        <div class="flex flex-col gap-6 px-8 lg:px-28 pt-10">
            <div class="hidden md:block animated-block">
                <p v-if="!isDiscoMode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out" :class="isDiscoMode ? 'opacity-0' : 'opacity-100'">
                    Impulsa tu carrera como DJ <br>
                    dando la oportunidad a tu<br>
                    público de elegir la música.
                </p>
                <p v-if="isDiscoMode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out" :class="isDiscoMode ? 'opacity-100' : 'opacity-0'">
                    Ofrece a tus clientes el poder<br>
                    de decidir la música y crea noches<br>
                    inolvidables en tu discoteca.
                </p>
            </div>

            <div class="md:hidden animated-block">
                <p v-if="!isDiscoMode" key="dj-mode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out opacity-100" :class="isDiscoMode ? 'opacity-0' : 'opacity-100'">
                    Impulsa tu carrera como DJ
                    dando la oportunidad a tu
                    público de elegir la música.
                </p>
                <p v-if="isDiscoMode" key="disco-mode" class="font-semibold text-2xl sm:text-5xl w-full xl:w-2/3 transition-opacity duration-500 ease-in-out opacity-0" :class="isDiscoMode ? 'opacity-100' : 'opacity-0'">
                    Ofrece a tus clientes el poder
                    de decidir la música y crea noches
                    inolvidables en tu discoteca.
                </p>
            </div>

            <div class="flex py-4 animated-block">
                <label class="flex gap-4 items-center cursor-pointer">
                    <span class="text-xl font-medium" :class="!isDiscoMode ? 'text-tp-yellow' : ''">DJ</span>
                    <div class="relative">
                        <input type="checkbox" v-model="isDiscoMode" class="sr-only" required>
                        <div class="block bg-tp-yellow w-14 h-8 rounded-full"></div>
                        <div
                            class="dot absolute left-1 top-1 bg-tp-black w-6 h-6 rounded-full transition-transform"
                            :class="isDiscoMode ? 'translate-x-full' : 'translate-x-0'"
                        ></div>
                    </div>
                    <span class="text-xl font-medium" :class="isDiscoMode ? 'text-tp-yellow' : ''">Discoteca</span>
                </label>
            </div>

            <div class="flex flex-col gap-14 sm:gap-10 text-xl text-tp-yellow w-full xl:w-1/2">
                <div class="flex flex-col gap-4 animated-block">
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6" >
                        <input v-model="form.name" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Nombre" required>
                        <input v-model="form.surname" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Apellidos" required>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6" >
                        <input v-model="form.phone" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Teléfono" required>
                        <input v-model="form.email" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Email" required>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6">
                        <input v-model="form.instagram" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="@ en Instagram" required>
                        <input v-model="form.location" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Zona de influencia" required>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6">
                        <input v-model="form.referral" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="¿Cómo nos has conocido?" required>
                    </div>
                </div>
                <div class="flex flex-col gap-6">          
                    <button @click="sendEmail" class="flex items-center gap-2 sm:gap-6 w-full cursor-pointer text-tp-yellow text-4xl sm:text-6xl font-semibold uppercase no-underline animated-block" href="">Enviar
                        <lord-icon
                            src="https://cdn.lordicon.com/whtfgdfm.json"
                            trigger="hover"
                            colors="primary:#F9F871" class="w-10 h-10 sm:w-24 sm:h-24">
                        </lord-icon>
                    </button>
                    <div v-if="formIncompleteMessage" class="text-red-500">{{ formIncompleteMessage }}</div>
                    <div v-if="submitStatus === 'success'" class="text-green-500">¡Correo enviado con éxito!</div>
                    <div v-if="submitStatus === 'error'" class="text-red-500">Error al enviar el correo.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import axios from 'axios';

const isDiscoMode = ref(false);
const formIncompleteMessage = ref('');

const form = ref({
    name: '',
    surname: '',
    email: '',
    role:'DJ',
    phone:'',
    referral:'',
    instagram:'',
    location:''
});

const isFormComplete = computed(() => {
  return form.value.name && form.value.surname && form.value.email && form.value.phone &&
         form.value.instagram && form.value.location;
});

const submitStatus = ref('');

const sendEmail = async () => {
    if (!isFormComplete.value) {
        console.log(form.value)
        formIncompleteMessage.value = 'Por favor, rellena todos los campos antes de enviar.';
        return; 
    }  
    formIncompleteMessage.value = '';
  try {
    const url = `https://api.dev.tuparty.club/contact`;
    const formData = new FormData();

    for (const key in form.value) {
      formData.append(key, form.value[key]);
    }

    const method = 'post';
    
    
    const response = await axios.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
    
    if (response.status === 200) {
      submitStatus.value = 'success';
      resetForm();
    } else {
      throw new Error('Error al enviar el correo.');
    }
  } catch (error) {
    console.error('Error:', error);
    submitStatus.value = 'error';
  }
};

const resetForm = () => {
  form.value.name = '';
  form.value.surname = '';
  form.value.email = '';
  form.value.phone = '';
  form.value.referral = '';
  form.value.instagram = '';
  form.value.location = '';
};

watch(isDiscoMode, (newValue) => {
    form.value.role = newValue ? 'disco' : 'DJ';
});

</script>

<style scoped>
.marquee {
    padding-left: -10%;
    animation: marquee-animation 80s linear infinite;
}

@keyframes marquee-animation {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.dot {
    transition: transform 0.3s ease;
}
.translate-x-full {
    transform: translateX(100%);
}
.translate-x-0 {
    transform: translateX(0);
}
</style>