<template>
    <div class="flex flex-col gap-6 px-8 lg:px-28">
        <p class="font-bold text-4xl sm:text-5xl xl:text-6xl animated-block">2 tipos de votaciones</p>
        <div class="flex flex-col lg:flex-row gap-10">
            <FeatureComponent class="feature-component animated-block" :title="featureTitle1" :description="featureDescription1" :video="mockup2" :flippedVideo="true" />
            <FeatureComponent class="feature-component animated-block" :title="featureTitle2" :description="featureDescription2" :video="mockup" />
        </div>
    </div>
</template>

<script setup>
import FeatureComponent from '@/components/Features/FeatureComponent.vue'
import mockup from '@/assets/videos/ranking-mockup.mp4'
import mockup2 from '@/assets/videos/4hits-mockup.mp4'

const featureTitle1 = "4-hits"	
const featureDescription1 = "Elige entre las selecciones del DJ"

const featureTitle2 = "Ranking"
const featureDescription2 = "Compite para que TU canción acabe en primera posición"
</script>